import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cn from 'clsx';
import elementOffset from 'utils/elementOffset';

// import Spy from './Scrollspy.styled';
import 'styles/components/Scrollspy.scss';
const Scrollspy = ({ children }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [menuItems, setMenuItems] = useState([]);

  const containerRef = useRef();
  const spyRef = useRef();

  const scrollHandler = useCallback(() => {
    if (menuItems.length > 0) {
      let lastID = 0;

      menuItems.forEach(({ el, id }) => {
        if (window.pageYOffset > elementOffset(el, 'top') - 150) lastID = id;
      });

      setActiveSection(lastID);
    }
  }, [menuItems]);

  const linkClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    window.scrollTo({
      top:
        elementOffset(
          document.querySelector(
            `[data-spy-id="${new URL(e.target.href).hash.replace('#', '')}"]`,
          ),
          'top',
        ) - 40,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const elements = containerRef.current.querySelectorAll('[data-spy-id]');

      setMenuItems(
        Array.from(elements).map((el) => ({
          id: el.dataset.spyId,
          label: el.dataset.spyTitle || el.dataset.spyId,
          el,
        })),
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <div ref={containerRef}>
      <div className="container" style={{ position: 'relative' }}>
        <div className="flex flex-wrap">
          <div className="flex-1">
            <div
              ref={spyRef}
              className={cn([
                'styled-scrollspy',
                { 'is-sticky': activeSection !== 0 },
              ])}>
              <div className="support-inner-links">
                {menuItems.map(({ id, label }) => (
                  <span className={id === activeSection ? 'is-active' : ''}>
                    <svg
                      x="0px"
                      y="0px"
                      viewBox="0 0 47.7 89.8"
                      style={{ enableBackground: 'new 0 0 47.7 89.8' }}>
                      <path d="M5,5l36.2,36.2c2.1,2.1,2.1,5.4,0,7.5L5.1,84.8" />
                    </svg>

                    <Link key={id} to={`#${id}`} onClick={linkClickHandler}>
                      {label}
                    </Link>
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1 support-inner-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

Scrollspy.propTypes = {
  // leadContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Scrollspy;
