import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import Main from './SupportHeader.styled';
import 'styles/components/SupportHeader.scss';

const SupportHeader = ({ toggleContactUs }) => (
  <div className="styled-support-header">
    <div className="container">
      <div className="flex">
        <div className="support-header-logo">
          <Link to="/">
            <img src="/assets/img/ruttl-logo-red.svg" alt="ruttl" />
          </Link>
        </div>
        <div className="support-header-nav">
          <a
            href="https://web.ruttl.com/"
            target="_blank"
            rel="noopener noreferrer">
            Login
          </a>
          <button
            type="button"
            onClick={toggleContactUs}
            className="support-nav-btn">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  </div>
);

SupportHeader.propTypes = {
  toggleContactUs: PropTypes.func,
};

export default SupportHeader;
