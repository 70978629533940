import React from 'react';
import { Link } from 'gatsby';
import 'styles/components/SupportInnerNav.scss';
// import Main from './SupportInnerNav.styled';

const SupportInnerNav = () => (
  <div className="support-innernav">
    <div className="container">
      <ul>
        <li>
          <Link to="/support/get-started/">Getting Started</Link>
        </li>
        <li>
          <Link to="/support/using-ruttl/">Using ruttl</Link>
        </li>
        <li>
          <Link to="/support/tools-and-tips/">Tools and Tips</Link>
        </li>
        <li>
          <Link to="/support/invite-people/">Invite People</Link>
        </li>
        <li>
          <Link to="/support/billing-and-plans/">Billing and Plans</Link>
        </li>
        <li>
          <a href="/support/#faq">FAQs</a>
        </li>
      </ul>
    </div>
  </div>
);

export default SupportInnerNav;
