import React from 'react';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
// import Faq from 'components/Faq';
import ArchivePostSingle from 'components/ArchivePostSingle';
import Meta from 'components/Meta';
import SupportHeader from 'components/SupportHeader';
import Scrollspy from 'components/Scrollspy';
// import theme from 'styles/theme';
// import Main from 'styles/supportInner';
// import { BlogListSupport } from 'styles/support';
import SupportInnerNav from '../../components/SupportInnerNav/SupportInnerNav';
import { signUp } from '../../utils/contentProvider/function';
import 'styles/supportInner.scss';
const SupportGetStarted = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => (
  <Layout hideHeader location={location}>
    {({ toggleContactUs, toggleSignup }) => (
      <>
        <Meta
          title="Support documentation 🔨 | ruttl"
          description="Effortlessly review websites, leave comments, and edit live. Invite stakeholders for precise and contextual feedback on pixel-perfect designs."
          url="https://ruttl.com/support/"
        />
        <main className="supportinner-styed-main">
          <SupportHeader toggleContactUs={toggleContactUs} />
          <section className="supportinner-hero">
            <div className="container">
              <h1>Get Started</h1>
              <p>
                Learn more about the basics of ruttl. Create your account and
                understand your actions in ruttl
              </p>
            </div>
          </section>
          <SupportInnerNav />
          <section className="support-inner-block">
            <Scrollspy>
              <div className="flex-1 support-inner-content">
                <img
                  src="/assets/img/support-get-started.png"
                  alt="get started icon"
                />

                <h2 data-spy-title="What is ruttl?" data-spy-id="what-is-ruttl">
                  What is ruttl?
                </h2>

                <p>
                  ruttl is a website feedback tool for live websites. Just like
                  Invision which is a feedback tool for static websites, ruttl
                  allows you to review live links or staging links.
                </p>

                <p>
                  Currently, in order to review developed websites, people take
                  screenshots and encircle the issues, and share a word doc with
                  the developer. Alternatively, they just sit with the
                  developers and ask them to make the changes. But these
                  processes are very tedious and time taking. On an average, a
                  single page takes around 3-4 hours to review, and still, the
                  final output is just close to perfection. That’s why we
                  created ruttl.
                </p>
                <p>
                  ruttl allows you to leave comments on live websites and make
                  real time edits so you can give precise, contextual feedback
                  to your team. It allows you to leave comments on live websites
                  and make real time edits such as content changes, text
                  alignments, image replacements, position change of web
                  elements, change in font properties, change in background
                  colours, change in button shape and its properties, switch
                  between desktop and mobile mode etc.
                </p>
                <h3
                  data-spy-title="Create your ruttl account"
                  data-spy-id="create-your-ruttl-account">
                  Create your ruttl account
                </h3>

                <p>
                  Creating your ruttl account is very simple. Anyone can sign up
                  and use ruttl for free. You can join as an individual or as a
                  team.
                </p>
                <p>There are 2 ways to sign up for Ruttl:</p>
                <ol type="1">
                  <li>Using Google Sign-up</li>
                  <li>Using your email address and a unique password</li>
                </ol>
                <p>
                  To sign-up using your Google account, simply click on the
                  button of ‘Continue with Google’, and login using your Gmail
                  address. Your account will be created on ruttl right away.
                </p>
                <ol type="1">
                  <li>
                    Head to Ruttl and click Sign Up in the top right corner.
                  </li>
                  <li>
                    Enter your email address and a unique password in the fields
                    provided.
                  </li>
                  <li>
                    Clicking on the ‘Create Account’ button will send a
                    verification email.
                  </li>
                  <li>
                    Click on the verification link, choose an avatar, and fill
                    in your name to complete the process.
                  </li>
                </ol>
                <p>
                  You will be logged into your new ruttl account immediately.
                </p>
                <p>
                  Here, you can set your display name, choose an avatar (if you
                  chose to ‘Continue with Google’, your default avatar will be
                  your Google account’s profile photo) and then start using
                  ruttl.
                </p>
                <h3
                  data-spy-title="How to use ruttl: Your quick start guide"
                  data-spy-id="how-to-use-ruttl">
                  How to use ruttl: Your quick start guide
                </h3>
                <p>
                  To start using ruttl, please create a project from the main
                  dashboard screen. Name your project and once it is created,
                  please copy the URL of the webpage you would like to review
                  and paste it inside the project. Click on ‘View page’ and your
                  webpage will start loading on ruttl.
                </p>
                <p>
                  The webpage loads in ‘Comment mode’ by default, and you shall
                  be able to comment on any part of your webpage. From the
                  bottom bar, switch to ‘Preview mode’ to simply view the entire
                  webpage. Switching to ‘Edit mode’ will bring up the most of
                  ruttl for you.
                </p>

                <p>
                  Here, you can edit content, change font properties, replace
                  images, change position of text, images and other web
                  elements, change button properties, switch between desktop and
                  mobile mode, and a lot more. Learn more by&nbsp;
                  <button type="button" onClick={() => signUp(toggleSignup)}>
                    signing up
                  </button>
                  &nbsp;or&nbsp;
                  <a
                    href="https://web.ruttl.com/"
                    target="_blank"
                    rel="nofollow noopener noreferrerF">
                    logging in
                  </a>
                  &nbsp;and start using ruttl.
                </p>

                <h3
                  data-spy-title="Understand your access in ruttl"
                  data-spy-id="understand-your-access-in-ruttl">
                  Understand your access in ruttl
                </h3>

                <p>
                  <span style={{ fontWeight: '600' }}>View access -</span>
                  Enables you to view all webpages inside this project, view all
                  comments and all the edits made to web pages inside this
                  project. However, you will not be able to comment or edit
                  anything inside this shared project.
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Comment mode -</span>
                  Enables you to see all comments and leave your comments on
                  live pixels of a webpage. You can also tag/mention your
                  teammates or your client, and get contextual feedback on the
                  webpages of this shared project. But you cannot make any edits
                  to the webpage.
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Edit mode -</span>
                  Enables you to manage everything - right from comments to
                  edits on all the pages inside the shared project.
                </p>
                <h3
                  data-spy-title="Navigate your ruttl project"
                  data-spy-id="navigate-your-ruttl-project">
                  Navigate your ruttl project
                </h3>
                <p>To navigate between your webpages, there are 2 ways:</p>

                <ol type="1">
                  <li>
                    If you are inside your project’s screen, simply select any
                    webpage and you will be able to load that specific webpage.
                  </li>
                  <li>
                    If you have loaded any webpage from your project, simply
                    hover to the top left position and click on the ‘Pages’
                    button. This button will also indicate the number of pages
                    added to this project.
                  </li>
                </ol>
                <h3
                  data-spy-title="Inviting users"
                  data-spy-id="inviting-users">
                  Inviting users
                </h3>
                <p>There are two ways to invite other users to your project:</p>
                <ol type="1">
                  <li>
                    Inviting via email ID of another user (login required from
                    another user too) - Hover on the card of the project which
                    you would like to share. On the top right, click on the
                    Share button. A pop-up window will appear. In this pop-up,
                    enter the email address of the user whom you want to invite,
                    select the level of access (View, Comment or Edit) and then
                    send invite. The user will receive an Invite email, and with
                    this email, the user will be able to access your ruttl
                    project.
                  </li>
                  <li>
                    Inviting via project’s shareable link (login not required
                    for sharing in View mode by default, but required for
                    Comment or Edit modes) - Suppose you wish to invite your
                    client to this project, but in most cases, your client would
                    not prefer logging in to any platform just to see the
                    progress. Hence, we have created this shareable link mode,
                    with the help of which, your client will be able to see all
                    the progress on the webpage, without any login. To do so,
                    click on share either from inside the project (top right
                    corner), inside the webpage (left side of the bottom bar),
                    or on the main home dashboard (top right corner of project
                    card). Once the share pop-up opens, toggle the Shareability
                    to ‘On’ position and then copy the link. Sharing this link
                    will enable your client to look at all the project
                    communications and project edits directly without any login.
                  </li>
                </ol>
              </div>
            </Scrollspy>
          </section>
          <main className="blog-list-support">
            <section
              className="bg-lightGrey"
              style={{
                // backgroundColor: theme.colors.lightGrey,
                padding: '10px 0',
              }}>
              <div className="container">
                <div className="other-articles-block">
                  <h3>Popular Articles</h3>
                  <ul>
                    {allPosts.map(({ node }) => (
                      <li key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </main>
          {/* <Faq id="faq" /> */}
        </main>
      </>
    )}
  </Layout>
);

SupportGetStarted.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportGetStarted;

export const pageQuery = graphql`
  query SupportGetStartedQuery {
    allContentfulBlogPost(
      filter: { knowledgeBox: { eq: false } }
      limit: 10
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
